import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalConstants} from "../../shared/global-constants";
import {Observable, of, Subscription} from 'rxjs';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {XftTransactionService} from '../../shared/services/log/xft-transaction.service';
import {ActivatedRoute} from '@angular/router';
import {XftTransactionLogErrorWarningDetailComponent} from '../xft-transaction-log-error-warning-detail/xft-transaction-log-error-warning-detail.component';
import {XftTransactionLogXmlComponent} from '../xft-transaction-log-xml/xft-transaction-log-xml.component';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {XftTransactionApiLogComponent} from '../xft-transaction-api-log/xft-transaction-api-log.component';
import {ApiLogService} from '../../shared/services/log/api-log.service';
import {Title} from '@angular/platform-browser';
import {shareReplay, tap} from 'rxjs/operators';
import {ViewportScroller} from '@angular/common';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-xft-transaction-log',
  templateUrl: './xft-transaction-log.component.html',
  styleUrls: ['./xft-transaction-log.component.scss']
})

export class XftTransactionLogComponent implements OnInit, OnDestroy {
  now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
  xftTransactionMsDurationWarning = GlobalConstants.xftTransactionMsDurationWarning;
  xftTransactionLogList: any[]|null;
  producerList$: Observable<any[]>;
  transactionList$: Observable<any[]>;
  channelList$: Observable<any[]>;
  versionList$: Observable<any[]>;
  statusList$: Observable<any[]>;
  xftUserList$: Observable<any[]>;
  networkList$: Observable<any[]>;
  subNetworkList$: Observable<any[]>;
  platformList$: Observable<any[]>;

  page: number = 1;
  pageSize: number;
  totalCount: number = 0
  okCount: number = 0
  errorCount: number = 0
  warningCount: number = 0
  pageCount: number = 1

  producer:string|null = null;
  transaction:string|null = null;
  requesterCode:string|null = null;
  requesterNetworkCode:string|null = null;
  requesterSubNetworkCode:string|null = null;
  channel:string|null = null;
  version:string|null = null;
  platformCode:string|null = null;
  status:string|null = null;
  productCode:string|null = null;
  productName:string|null = null;
  emptyDate: NgbDateStruct;
  transactionDateFrom:NgbDateStruct;
  transactionDateTo:NgbDateStruct;
  departureDateFrom:NgbDateStruct;
  departureDateTo:NgbDateStruct;
  departureCity:string|null = null;
  bookingReference:string|null = null;
  errorWarningCode:string|null = null;

  dateNow: NgbDateStruct;
  maxDate: NgbDateStruct;

  TransactionTimeFromHour: string = '00';
  TransactionTimeFromMinute: string = '00';
  TransactionTimeToHour: string = '23';
  TransactionTimeToMinute: string = '59';

  loadingEngine: boolean = true;
  loadingXftUser: boolean = true;
  loadingPlatform: boolean = true;
  currentLigne: number = null;
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  xftTransactionSearchEngineSubscription: Subscription
  xftTransactionSearchSubscription: Subscription
  getXftTransactionSubscription: Subscription
  getXftTransactionRqRsSubscription: Subscription
  apiLogSearchSubscription: Subscription
  constructor(private xftTransactionService: XftTransactionService,private xftUserService: XftUserService,private parameterService: ParameterService,private title: Title,private notifyService : NotificationService,private utilsService : UtilsService,private apiLogService: ApiLogService,private route: ActivatedRoute,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService,private viewportScroller: ViewportScroller) { }

  ngOnInit(): void {
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Logs transactions XFT - Maxula Travel");
    this.pageSize = GlobalConstants.pageSize;
    this.utilsService.loadingShow();
    this.dateNow = {year:this.now.getFullYear(), month:this.now.getMonth() + 1, day:this.now.getDate()};
    this.maxDate = this.dateNow;
    this.transactionDateFrom = this.dateNow;
    this.transactionDateTo = this.dateNow;
    const statusList = [
      {'code': 'ok','name':'Succès'},
      {'code': 'error','name':'Erreur'},
      {'code': 'warning','name':'Alerte'}
    ];
    this.statusList$ = of(statusList).pipe();
    this.platformList$ = this.parameterService.getPlatform().pipe(tap(() => this.loadingPlatform = false),shareReplay(1));
    this.xftTransactionSearchEngineSubscription = this.xftTransactionService.xftTransactionSearchEngine().subscribe(
      searchEngine => {
        // PRODUCER
        this.producerList$ = of(Object.values(searchEngine.producer).map(producer => ({ code: producer }))).pipe(shareReplay(1));
        // TRANSACTION
        this.transactionList$ = of(Object.values(searchEngine.transactionCode).map(transaction => ({ code: transaction }))).pipe(tap(() => this.loadingEngine = false),shareReplay(1));
        // CHANNEL
        this.channelList$ = of(Object.values(searchEngine.channel).map(channel => ({ code: channel }))).pipe(shareReplay(1));
        // VERSION
        this.versionList$ = of(Object.values(searchEngine.version).map(version => ({ code: version }))).pipe(shareReplay(1));
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
    // REQUESTER
    this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));
    // NETWORK
    this.networkList$ = this.xftUserService.getXftUsersNetworks();
    // SUB-NETWORK
    this.subNetworkList$ = this.xftUserService.getXftUsersSubNetworks();

    this.searchLog()

    const breadcrumbs  =  [
      {
        label: 'Accueil',
        url: '/'
      },
      {
        label: 'Logs',
        url: ''
      },
      {
        label: 'Logs transactions XFT',
        url: ''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  ngOnDestroy() {
    if (this.xftTransactionSearchEngineSubscription) {
      this.xftTransactionSearchEngineSubscription.unsubscribe();
    }
    if (this.xftTransactionSearchSubscription) {
      this.xftTransactionSearchSubscription.unsubscribe();
    }
    if (this.getXftTransactionSubscription) {
      this.getXftTransactionSubscription.unsubscribe();
    }
    if (this.getXftTransactionRqRsSubscription) {
      this.getXftTransactionRqRsSubscription.unsubscribe();
    }
    if (this.apiLogSearchSubscription) {
      this.apiLogSearchSubscription.unsubscribe();
    }
  }

  onSubmit() {
    this.page = 1;
    this.searchLog()
  }
  searchLog() {
    this.currentLigne = null
    this.utilsService.loadingShow();
    this.xftTransactionSearchSubscription = this.xftTransactionService.xftTransactionSearch(
      this.producer,
      this.transaction,
      this.requesterCode,
      this.requesterNetworkCode,
      this.requesterSubNetworkCode,
      this.channel,
      this.version,
      this.status,
      this.transactionDateFrom,
      this.transactionDateTo,
      this.TransactionTimeFromHour,
      this.TransactionTimeFromMinute,
      this.TransactionTimeToHour,
      this.TransactionTimeToMinute,
      this.productCode,
      this.productName,
      this.departureCity,
      this.departureDateFrom,
      this.departureDateTo,
      this.bookingReference,
      this.errorWarningCode,
      this.platformCode,
      this.page
    ).subscribe(
      xftTransactionLogList => {
        this.totalCount = xftTransactionLogList.totalCount;
        this.errorCount = xftTransactionLogList.errorCount;
        this.warningCount = xftTransactionLogList.warningCount;
        this.okCount = this.totalCount - this.errorCount - this.warningCount;
        this.pageCount = xftTransactionLogList.nbPage;
        this.xftTransactionLogList = [];
        for (let item in xftTransactionLogList.xftTransactionLog) {
          this.xftTransactionLogList.push(xftTransactionLogList.xftTransactionLog[item]);
        }
        this.utilsService.loadingHide();
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }

  paginate(page: number|null = null) {
    this.currentLigne = null
    this.page = page ?? this.page;
    if(this.page>this.pageCount) {
      this.page = this.pageCount-1
    }
    this.searchLog()
    this.viewportScroller.scrollToAnchor('scrollTo');

  }

  clearDepartureDate() {
    this.departureDateFrom = this.emptyDate;
    this.departureDateTo = this.emptyDate;
  }

  getErrorWarningDetail(id:number,profilId:string) {
    this.currentLigne = id
    this.utilsService.loadingShow();
    this.getXftTransactionSubscription = this.xftTransactionService.getXftTransaction(id).subscribe(
      xftTransactionLog => {
        const modalRef = this.modalService.open(XftTransactionLogErrorWarningDetailComponent,{ size: 'lg'});
        modalRef.componentInstance.xftTransactionLog = xftTransactionLog;
        modalRef.componentInstance.profilId = profilId;
        this.utilsService.loadingHide();
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }
  getXml(id:number,profilId:string) {
    this.currentLigne = id
    this.utilsService.loadingShow();
    this.getXftTransactionRqRsSubscription = this.xftTransactionService.getXftTransactionRqRs(id).subscribe(
        getXftTransactionRqRs => {
          const modalRef = this.modalService.open(XftTransactionLogXmlComponent,{ size: 'lg'});
          modalRef.componentInstance.xftTransactionLogId = id;
          modalRef.componentInstance.profilId = profilId;
          modalRef.componentInstance.xftTransactionLogRqRs = getXftTransactionRqRs;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }
  getApiLog(id:number,profilId:string) {
    this.currentLigne = id
    this.utilsService.loadingShow();
    this.apiLogSearchSubscription = this.apiLogService.apiLogSearch(profilId ?? 'xxx',null,null,null,null,null,undefined,undefined,null,null,null,null,-1).subscribe(
        getApiLog => {
          const modalRef = this.modalService.open(XftTransactionApiLogComponent,{ size: 'xl'});
          let apiLogList = [];
          for (let item in getApiLog.apiLog) {
            apiLogList.push(getApiLog.apiLog[item]);
          }
          modalRef.componentInstance.apiLogList = apiLogList;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }

    /*plateformeName(ip: string) {
        let plateformeName = {
          '127.0.0.1':'Local',
          'localhost':'Local',
          '51.68.91.137':'Dev',
          '41.226.250.41':'CfTravel',
          '89.185.32.1':'Orchestra',
        };
        return plateformeName[ip] ?? '';
    }*/
    customSearchClient(term: string, item) {
      term = term.toLocaleLowerCase();
      return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
    }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
}
